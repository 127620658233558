<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="modelRef" :labelCol="{ span: 3 }" :wrapperCol="{ span: 16 }"
				@finish="onSubmit">
				<a-form-item label="是否长期有效" name="isLongTime" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-radio-group v-model:value="modelRef.isLongTime">
						<a-radio :value="1">是</a-radio>
						<a-radio :value="0">否</a-radio>
					</a-radio-group>
				</a-form-item>

				<div v-if="!modelRef.isLongTime">
					<a-form-item label="到期时间" name="expirationTime" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-date-picker v-model:value="modelRef.expirationTime" />
					</a-form-item>
				</div>

				<a-form-item label="收款二维码" name="qrCode" :rules="[{ required: true, message: '请上传收款二维码' }]" extra="图片大小不得大于2M，建议宽高比: 1：1">
					<a-upload v-model:file-list="fileList" name="file" list-type="picture-card"
						class="avatar-uploader" action="/admin/ajaxUpload.do" :before-upload="beforeUpload"
						@change="handleChange" @remove="modelRef.qrCode = ''">
						<div v-if="fileList.length < 1">
							<div class="ant-upload-text">上传</div>
						</div>
					</a-upload>
				</a-form-item>

				<a-form-item style="margin: 30px 0 60px 0;text-align: center;">
					<a-button type="primary" html-type="submit" v-permission="['system_manage_renewal_submit']">提交</a-button>
				</a-form-item>

				<a-form-item label="系统管理员">
					<a-button type="primary" @click="onAdd" v-permission="['system_manage_renewal_add']">增加管理员</a-button>

					<a-form-item-rest>
						<a-table style="margin-top: 10px;" :dataSource="list" :columns="columns" :pagination="false" size="small">
							<template #bodyCell="{ column, record, index }">
								<template v-if="column.key === 'phone'">
									{{ record.phone || '--' }}
								</template>
								<template v-if="column.key === 'isDisabled'">
									<span v-if="record.isDisabled">已禁用</span>
									<span v-else>已启用</span>
								</template>
								<template v-if="column.key === 'action'">
									<a-button type="link" @click="onRemove(record)" v-permission="['system_manage_renewal_remove']">移除</a-button>
								</template>
							</template>
						</a-table>
					</a-form-item-rest>
				</a-form-item>
			</a-form>

			<a-modal v-model:visible="visible" title="选择用户" width="950px" @ok="onModalOk">
				<a-spin :spinning="loading">
					<a-form ref="formRef" :model="formState" name="formRef" @finish="onSearch">
						<a-row>
							<a-form-item class="ui-form__item" label="账号" name="username">
								<a-input placeholder="请输入账号" v-model:value="formState.username" />
							</a-form-item>

							<a-form-item class="ui-form__item" label="用户名" name="nickname">
								<a-input placeholder="请输入用户名" v-model:value="formState.nickname" />
							</a-form-item>

							<a-form-item class="ui-form__item" label="手机号" name="phone">
								<a-input placeholder="请输入手机号" v-model:value="formState.phone" />
							</a-form-item>
						</a-row>
						<a-row type="flex">
							<a-col :span="10">
							</a-col>
							<a-col :span="14" style="text-align: right;">
								<a-button type="primary" html-type="submit">搜索</a-button>
								<a-button style="margin-left: 10px" @click="resetBtn">重置</a-button>
							</a-col>
						</a-row>
					</a-form>

					<a-table style="margin-top: 10px;" :dataSource="dataList" :pagination="pagination"
						:columns="dataColumns" rowKey="id"
						:rowSelection="{ type: type, selectedRowKeys: selectedRowKeys, getCheckboxProps: getCheckboxProps, onSelect: onSelectChange, onSelectAll: onSelectAll }">
						<template #bodyCell="{ column, record }">
							<template v-if="column.key === 'roles'">
								<a-tag v-for="item in record.adminRoleList" :key="item.id">{{ item.title }}</a-tag>
							</template>
							<template v-if="column.dataIndex === 'createTime'">
								{{ transDateTime(record.createTime) }}
							</template>
							<template v-if="column.key === 'lastTime'">
								{{ transDateTime(record.lastTime)  }}
							</template>
							<template v-if="column.key === 'isDisabled'">
								<span v-if="record.isDisabled">已禁用</span>
								<span v-else>已启用</span>
							</template>
						</template>
					</a-table>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		userList,
		updateAdminUser,
		getRenew,
		updateRenew
	} from "@/service/modules/system";
	import {
		Icon
	} from "@/components/icon/icon.js";
	import store from '@/store';
	export default {
		components: { Icon },
		data() {
			return {
				loading: false,
				modelRef: {
					isLongTime: 1,
					time: null
				},
				fileList: [],
				columns: [{
						title: "账号",
						dataIndex: "username",
					},
					{
						title: "用户名",
						dataIndex: "nickname",
					},
					{
						title: "手机号",
						dataIndex: "phone",
						key: 'phone'
					},
					{
						title: '是否启用',
						key: 'isDisabled'
					}, {
						title: '操作',
						key: 'action'
					}
				],
				list: [],

				type: 'checkbox',
				visible: false,
				selectedRowKeys: [],
				selectedListData: [],
				formState: {},
				searchData: {},
				dataList: [],
				dataColumns: [{
						title: "账号",
						dataIndex: "username",
					},
					{
						title: "用户名",
						dataIndex: "nickname",
					},
					{
						title: "手机号",
						dataIndex: "phone",
					},
					{
						title: "关联角色",
						key: 'roles'
					},
					{
						title: '是否启用',
						key: 'isDisabled'
					}, {
						title: '创建者',
						dataIndex: 'createUserName'
					}
				],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 20,
					onChange: (page) => {
						this.pagination.current = page;
						this.getUserData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getUserData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			async onSubmit() {
				let postData = this.$deepClone(this.modelRef);
				postData.expirationTime = postData.expirationTime.endOf('days').unix();
				this.loading = true;
				try {
					let ret = await updateRenew(postData);
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('修改成功');
						this.getData();
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onAdd() {
				this.selectedRowKeys = this.list.map(item => {
					return item.id;
				});
				this.visible = true;
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getRenew({});
					this.loading = false;
					if (ret.code === 200) {
						ret.data.renewSetting.expirationTime = this.moment(ret.data.renewSetting.expirationTime * 1000);
						this.modelRef = ret.data.renewSetting;
						store.commit('system/reneSetting', ret.data.renewSetting);
						this.list = ret.data.adminUserList;
						if (this.modelRef.qrCode) {
							this.fileList = [{uid: 0, name: this.modelRef.qrCode, url: this.modelRef.qrCode}]
						}
					}
				} catch(e) {
					this.loading = false;
				} 
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getUserData();
			},
			resetBtn() {
				this.$refs.formRef.resetFields();
				this.onSearch();
			},
			async getUserData() {
				this.loading = true;
				let postData = {
					page: this.pagination.current,
				 pageSize: this.pagination.pageSize,
					...this.searchData
				};
				let res = await userList(postData);
				this.loading = false;
				if (res.code == 200) {
					this.dataList = res.data.list;
					this.pagination.total = res.data.totalCount;
				}
			},
			async onModalOk() {
				this.loading = true;
				try {
					let ret = await updateAdminUser({
						ids: this.selectedRowKeys.join(','),
						type: 1
					})
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('新增成功!');
						this.getData();
						this.visible = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onRemove(item) {
				this.loading = true;
				try {
					let ret = await updateAdminUser({
						ids: item.id,
						type: 2
					})
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('移除成功!');
						this.getData();
					}
				} catch(e) {
					this.loading = false;
				}
			},
			getCheckboxProps(record) {
				return {
					id: record.id + '',
					disabled: record.isAdmin === 1
				}
			},
			onSelectChange(record, selected) {
				if (this.type === 'checkbox') {
					if (selected) {
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					}
				} else {
					if (selected) {
						this.selectedRowKeys = [record.id];
					} else {
						this.selectedRowKeys = [];
					}
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('上传文件格式不正确');
				}
				const isLtSize = file.size / 1024 / 1024 < 2;
				if (!isLtSize) {
					this.$message.error('上传文件太大了');
				}
				return isJpgOrPng && isLtSize;
			},
			handleChange(info) {
				if (!info.file.status) {
					this.fileList.splice(this.fileList.length - 1, 1);
				}
				if (info.file.status === 'done') {
					// this.fileList = info.fileList;
					this.modelRef.imgUrl = [];
					const $fileList = [...info.fileList];
					$fileList.map(file => {
						if (file.response) {
							this.modelRef.qrCode = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 20px;
	}
</style>
